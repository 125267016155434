import "./App.css";
import React, { Component } from "react";
import axios from "axios";

class App extends Component {
  state = {
    selectedFileCSV: null,
    fileUploadedSuccesfullyCSV: false,
    selectedFileExcel: null,
    fileUploadedSuccesfullyExcel: false,
  };

  // Para cargar el archivo CSV
  onFileChangeCSV = (event) => {
    this.setState({ selectedFileCSV: event.target.files[0] });
  };

  onFileUploadCSV = () => {
    if (!this.state.selectedFileCSV) {
      alert("Por favor selecciona un archivo CSV primero.");
      return;
    }

    const formData = new FormData();
    formData.append("demo file", this.state.selectedFileCSV, this.state.selectedFileCSV.name);

    // Llamada a la API para cargar CSV
    axios
      .post(
        "https://p9blp1rmy8.execute-api.us-east-1.amazonaws.com/prod/file-upload/",
        formData
      )
      .then(() => {
        this.setState({
          selectedFileCSV: null,
          fileUploadedSuccesfullyCSV: true,
        });
      })
      .catch((error) => {
        console.error("Error al subir el archivo CSV:", error);
        alert("Hubo un error al subir el archivo CSV. Inténtalo de nuevo.");
      });
  };

  // Para cargar el archivo Excel (conversión a base64)
  onFileChangeExcel = (event) => {
    this.setState({ selectedFileExcel: event.target.files[0] });
  };

  onFileUploadExcel = () => {
    if (!this.state.selectedFileExcel) {
      alert("Por favor, selecciona un archivo antes de subirlo.");
      return;
    }
  
    const reader = new FileReader();
  
    reader.onloadend = () => {
      // Base64 del archivo
      const base64Data = reader.result.split(",")[1]; // Solo el contenido base64
      const fileName = this.state.selectedFileExcel.name; // Nombre del archivo
  
      axios
        .post(
          "https://94cgu3pzsf.execute-api.us-east-1.amazonaws.com/PRO/files",
          {
            fileName: fileName, // Nombre del archivo
            fileData: base64Data, // Archivo en Base64
          },
          {
            headers: {
              "x-api-key": "ro9TNKarWh4OOOitpaEmk94FsTt7vl6X7dAe4g4D", // Clave API
              "Content-Type": "application/json", // Formato JSON
            },
          }
        )
        .then(() => {
          this.setState({
            selectedFileExcel: null,
            fileUploadedSuccesfullyExcel: true,
          });
          alert("Archivo subido con éxito.");
        })
        .catch((error) => {
          console.error("Error al subir el archivo Excel:", error.response || error);
          alert("Hubo un error al subir el archivo Excel. Inténtalo de nuevo.");
        });
    };
  
    reader.readAsDataURL(this.state.selectedFileExcel); // Lee el archivo y lo convierte a Base64
  };

  // Mostrar los detalles del archivo
  fileDataCSV = () => {
    if (this.state.selectedFileCSV) {
      return (
        <div>
          <h2>Detalles del archivo CSV:</h2>
          <p>Nombre: {this.state.selectedFileCSV.name}</p>
          <p>Tipo: {this.state.selectedFileCSV.type}</p>
        </div>
      );
    } else if (this.state.fileUploadedSuccesfullyCSV) {
      return (
        <div>
          <br />
          <h4>El archivo CSV ha sido cargado</h4>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Selecciona un archivo CSV y haz click en cargar</h4>
        </div>
      );
    }
  };

  fileDataExcel = () => {
    if (this.state.selectedFileExcel) {
      return (
        <div>
          <h2>Detalles del archivo Excel:</h2>
          <p>Nombre: {this.state.selectedFileExcel.name}</p>
          <p>Tipo: {this.state.selectedFileExcel.type}</p>
        </div>
      );
    } else if (this.state.fileUploadedSuccesfullyExcel) {
      return (
        <div>
          <br />
          <h4>El archivo Excel ha sido cargado</h4>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Selecciona un archivo Excel y haz click en cargar</h4>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="container">
        <h1>Cargar archivos CSV</h1>
        <div className="buttons">
          <input
            className="inputFile"
            type="file"
            onChange={this.onFileChangeCSV}
          />
          <button onClick={this.onFileUploadCSV}>Cargar CSV</button>
        </div>
        {this.fileDataCSV()}

        <h1 style={{ marginTop: "50px" }}>Cargar archivos Excel</h1>
        <div className="buttons">
          <input
            className="inputFile"
            type="file"
            onChange={this.onFileChangeExcel}
          />
          <button onClick={this.onFileUploadExcel}>Cargar Excel</button>
        </div>
        {this.fileDataExcel()}
      </div>
    );
  }
}

export default App;